import React from "react";
import ProjectModal, {ProjectModalState} from "./ProjectModal";
import "./styles/CartridgeRack.scss"

import projectInfo from "../res/project-info.json"
import {useNavigate} from "react-router";
import SoloCartridge from "./SoloCartridge";
import {TProject} from "../datatypes/ProjectsDatatype";

interface CartridgeRackProps {

    modal: React.RefObject<ProjectModal>;

}

const CartridgeRack = (props:CartridgeRackProps) => {

    const physEngineProject = projectInfo.projects[0];
    const modIOProject = projectInfo.projects[1];
    const permanenceProject = projectInfo.projects[2];
    const purrfectProject = projectInfo.projects[3];

    const navigate = useNavigate();


    const openModal = (newModalState: ProjectModalState): void => {

        props.modal.current?.setState({
            imageUrl: newModalState.imageUrl,
            writeup: newModalState.writeup,
            title: newModalState.title,
        })

        props.modal.current?.setOpenState(true);

    }

    return (
        <div className="cartridge-rack">
            {
                projectInfo.projects.map((project: TProject, index) => {
                    if(project.visible) {
                        return (
                            <SoloCartridge projectPage={`/project/${index}`} model={project.modelUrl}
                                           name={project.name} textColor={"#FFFFFF"}/>
                        )
                    }
                })
            }


        </div>
    );
}

export default CartridgeRack;