import React, {useEffect, useRef} from 'react';
import './App.scss';
import { Avatar } from './components/Avatar';
import Home from './pages/Home';

import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import PageDivider from './components/PageDivider';

import {Route, Routes, useLocation} from "react-router";
import ProjectPageProvider from "./pages/providers/ProjectPageProvider";
import EditorPage from "./pages/EditorPage";




function App() {

  const LandingPage = () => {


      const { state } = useLocation();
      const { targetId } = state || {};

      useEffect(() => {
          const el = document.getElementById(targetId);
          if (el) {
              el.scrollIntoView();
          }
      }, [targetId]);

    return (

        <main className='main'>

          <Home/>

          <PageDivider/>
          <About/>

          <PageDivider/>
          <Projects/>

          <PageDivider/>
          <Contact/>
            <section className='Footer'>
                <div className='footer-socials'>
                    <a href="https://github.com/margoovian" target="_blank" rel="noopener noreferrer"
                       className='footer-github'/>
                    <a href="https://www.linkedin.com/in/devlyn-hendrie/" target="_blank" rel="noopener noreferrer"
                       className='footer-linkedin'/>
                    <a href="https://x.com/sifterau" target="_blank" rel="noopener noreferrer" className='footer-twitter'/>
                    <a href="https://soundcloud.com/sifterau" target="_blank" rel="noopener noreferrer"
                       className='footer-soundcloud'/>
                    <a href="https://margoovian.itch.io/" target="_blank" rel="noopener noreferrer" className='footer-itchio'/>
                </div>
                <h3>© Devlyn Hendrie. All rights reserved.</h3>

            </section>

        </main>
    );
  }


    return (
        <div className="App">

            <Routes>
                <Route path="/" element={<LandingPage/>}/>
                <Route path="/editor" element={<EditorPage/>}/>
                <Route path="/project/:pid" element={<ProjectPageProvider/>}/>
            </Routes>


        </div>
    );
}

export default App;
