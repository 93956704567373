
import "./styles/EditorPage.scss"
import React, {
    DetailedHTMLProps,
    InputHTMLAttributes,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import {TContent, TImage, TLanguage, TLink, TProject, TProjects, TSection, TTag} from "../datatypes/ProjectsDatatype";
import defaultData from "../res/default-projects.json"
import {faceForward} from "three/src/nodes/math/MathNode";

interface PropsWithClass {
    className?: string;
}
interface PropsWithRemoveFunction {
    onRemove: Function;
}
interface LayoutProps extends PropsWithClass {
    gap?: number;
}
interface LanguageProps extends PropsWithClass, PropsWithRemoveFunction {
    language: TLanguage;
}
interface OverviewImageProps extends PropsWithClass, PropsWithRemoveFunction {
    imageUrl: string;
    setter: (ref: React.RefObject<HTMLInputElement>) => void;
}
interface SectionProps extends PropsWithClass, PropsWithRemoveFunction {
    section: TSection;
}
interface SectionTagProps extends PropsWithClass, PropsWithRemoveFunction {
    tag: TTag;
}
interface ContentProps extends PropsWithClass, PropsWithRemoveFunction {
    content: TContent;
}
interface ContentImageProps extends PropsWithClass, PropsWithRemoveFunction {
    image: TImage;
}
interface LinkProps extends PropsWithClass, PropsWithRemoveFunction {
    link: TLink;
}

function Stack(props:PropsWithChildren<LayoutProps>) {
    return (
        <div className={props.className? "stack " + props.className: "stack" }  style={props.gap ? { gap: props.gap } : undefined}>
            {props.children}
        </div>
    );

}
function Row(props:PropsWithChildren<LayoutProps>) {
    return (
        <div className={props.className? "row " + props.className: "row" } style={props.gap ? { gap: props.gap } : undefined}>
            {props.children}
        </div>
    );

}

function Language(props: LanguageProps){

    const languageRef = useRef<HTMLInputElement>(null);
    const hexRef = useRef<HTMLInputElement>(null);
    const [, setLanguage] = useState<string>(props.language.language);
    const [hex, setHex] = useState<string>(props.language.hex);

    const onTextChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.language.language = e.currentTarget.value;
        setLanguage(e.currentTarget.value);
    }

    const onHexChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.language.hex = e.currentTarget.value;
        setHex(e.currentTarget.value);
    }

    useEffect(() => {
        if(languageRef.current !== null){
            languageRef.current.value = props.language.language;
            setLanguage(languageRef.current.value);
        }
        if(hexRef.current !== null){
            hexRef.current.value = props.language.hex;
            setHex(hexRef.current.value);
        }
    }, [hexRef,languageRef,props, setHex, setLanguage]);

    return(
        <Row gap={4} className={props.className ? "language-box " + props.className : "language-box"}>
            <input ref={languageRef} onChange={onTextChanged} className="language-text-input" type="text" placeholder="Language"/>
            <input ref={hexRef} onChange={onHexChanged} className="language-hex-input" type="text" placeholder="Hexcode"/>
            <div style={{backgroundColor: hex}} className="language-hex-preview"/>
            <button onClick={ () => { props.onRemove() } } className="language-remove">-</button>
        </Row>

    );
}
function OverviewImage(props: OverviewImageProps){

    const [url, setUrl] = useState<string>(props.imageUrl);
    const urlRef = useRef<HTMLInputElement>(null);

    const onUrlChanged = (e: React.FormEvent<HTMLInputElement>) => {
        setUrl(e.currentTarget.value);
        props.setter(urlRef);
    }

    useEffect(()=>{
        if(urlRef.current !== null){
            setUrl(props.imageUrl);
            urlRef.current.value = url;

        }
    },[props.imageUrl, urlRef, setUrl, url]);

    return (
        <Row className={props.className ? "image-box " + props.className : "image-box"}>
            <h4>Image Url</h4>
            <input ref={urlRef} onChange={onUrlChanged} className="image-url-input" type="text" placeholder="Image Url"/>
            <button onClick={() => {props.onRemove()}} className="image-url-remove">-</button>

        </Row>
    );
}
function ContentImage(props: ContentImageProps){
    const [url, setUrl] = useState<string>(props.image.url);
    const [width, setWidth] = useState<number>(props.image.width);
    const [height, setHeight] = useState<number>(props.image.height);

    const urlRef = useRef<HTMLInputElement>(null);
    const widthRef = useRef<HTMLInputElement>(null);
    const heightRef = useRef<HTMLInputElement>(null);

    const onUrlChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.image.url = e.currentTarget.value;
        setUrl(e.currentTarget.value);
        //props.setter(urlRef);
    }

    const onWidthChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.image.width = parseInt(e.currentTarget.value);
        setWidth(parseInt(e.currentTarget.value));
        //props.setter(urlRef);
    }

    const onHeightChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.image.height = parseInt(e.currentTarget.value);
        setHeight(parseInt(e.currentTarget.value));
        //props.setter(urlRef);
    }

    useEffect(()=>{
        if(urlRef.current !== null){
            setUrl(urlRef.current.value);
            urlRef.current.value = url;

        }
        if(widthRef.current !== null){
            setWidth(parseInt(widthRef.current.value));
            widthRef.current.value = width.toString();

        }
        if(heightRef.current !== null){
            setHeight(parseInt(heightRef.current.value));
            heightRef.current.value = height.toString();

        }
    },[props.image, urlRef, setUrl, url, width, height]);

    return (
        <Row  className={props.className ? "image-box " + props.className : "image-box"}>
            <h4>Image Url</h4>
            <input ref={urlRef} onChange={onUrlChanged} className="image-url-input" type="text"
                   placeholder="Image Url"/>
            <input ref={widthRef}  onChange={onWidthChanged} className="image-width-input" type="number" placeholder="Width"/>
            <input ref={heightRef} onChange={onHeightChanged} className="image-height-input" type="number" placeholder="Height"/>
            <button onClick={() => {
                props.onRemove()
            }} className="image-url-remove">-
            </button>

        </Row>
    );
}
function SectionTag(props: SectionTagProps) {
    const tagRef = useRef<HTMLInputElement>(null);
    const hexRef = useRef<HTMLInputElement>(null);
    const [, setTag] = useState<string>(props.tag.tag);
    const [hex, setHex] = useState<string>(props.tag.hex);

    const onTextChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.tag.tag = e.currentTarget.value;
        setTag(e.currentTarget.value);
    }

    const onHexChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.tag.hex = e.currentTarget.value;
        setHex(e.currentTarget.value);
    }

    useEffect(() => {
        if(tagRef.current !== null){
            tagRef.current.value = props.tag.tag;
            setTag(tagRef.current.value);
        }
        if(hexRef.current !== null){
            hexRef.current.value = props.tag.hex;
            setHex(hexRef.current.value);
        }
    }, [hexRef,tagRef,props, setHex, setTag]);

    return(
        <Row gap={4} className={props.className ? "tag-box " + props.className : "tag-box"}>
            <input ref={tagRef} onChange={onTextChanged} className="tag-text-input" type="text" placeholder="Language"/>
            <input ref={hexRef} onChange={onHexChanged} className="tag-hex-input" type="text" placeholder="Hexcode"/>
            <div style={{backgroundColor: hex}} className="tag-hex-preview"/>
            <button onClick={ () => { props.onRemove() } } className="tag-remove">-</button>
        </Row>

    );
}
function Content(props: ContentProps) {
    const subheadingRef = useRef<HTMLInputElement>(null);
    const textRef = useRef<HTMLTextAreaElement>(null);

    const [subheading, setSubheading] = useState<string>(props.content.subheading);
    const [text, setText] = useState<string>(props.content.text);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [, refresh] = useState<number>();
    const forceUpdate = useCallback(() => refresh(Math.random()),[])

    const onImageAdd = () => {

        let newValue:TImage = {height: 0, url: "image/url", width: 0};
        props.content.images.push(newValue);
        forceUpdate();
    }

    const onImageRemove = (index: number) => {
        props.content.images.splice(index, 1);
        forceUpdate();
    }

    const onTextChange = (e : React.ChangeEvent<HTMLTextAreaElement>) => {
        props.content.text = e.target.value;
        setText(e.target.value);
    }

    const onSubheadingChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        props.content.subheading = e.target.value;
        setSubheading(e.target.value);
    }

    const openCloseBox = (value: boolean) => {

        setIsOpen(value);
        setElements();

    }

    const setElements = () => {
        if(textRef.current !== null) {
            setText(props.content.text);
            textRef.current.value = text;
        }

        if(subheadingRef.current !== null) {
            setSubheading(props.content.subheading);
            subheadingRef.current.value = subheading;
        }
    }

    useEffect(()=>{

        setElements();

    },[text, setText, subheading, setSubheading, textRef, subheadingRef, props.content.text, props.content.subheading, setElements]);

    return (
        <Stack gap={4} className="editor-content-box">
            {

                isOpen? <>
                        <Row gap={8}>
                            <h4>Subheading</h4>
                            <input ref={subheadingRef} onChange={onSubheadingChange} className="content-subheading-input"
                                   type="text" placeholder="Subheading"/>
                            <button onClick={() => {
                                props.onRemove();
                            }} className="content-remove">-
                            </button>
                            <button onClick={() => {
                                setIsOpen(false);
                            }} className="project-expand-minimise-button">Minimise
                            </button>

                        </Row>
                    </> :

                    <>

                        <Row gap={8}>
                            <h4>{props.content.subheading}</h4>
                            <button onClick={()=>{setIsOpen(true);}} className="project-expand-minimise-button">Expand</button>
                        </Row>

                    </>


            }

            {isOpen?
            <>
            <h5 className="content-text-label">Text</h5>
            <textarea ref={textRef} onChange={onTextChange} className="content-text-input" placeholder="Write up" cols={2} rows={2}/>
            <Row gap={8}>
                <h5>Images</h5>
                <button onClick={onImageAdd} className="content-image-add">+</button>
            </Row>

            <div className="content-image-box">
                {props.content.images.map((image, i)=> {
                    return (
                        <ContentImage onRemove={()=>{onImageRemove(i)}} image={image} ></ContentImage>
                    );
                })}
            </div>
            </>: <></>
}
        </Stack>
    );
}

function Section(props: SectionProps) {

    const titleRef = useRef<HTMLInputElement>(null);
    const [title, setTitle] = useState<string>(props.section.title);

    const [, refresh] = useState<number>();
    const forceUpdate = useCallback(() => refresh(Math.random()),[])

    const onTitleChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.section.title = e.currentTarget.value;
        setTitle(e.currentTarget.value);
    }
    const onContentAdd = () => {
        let content: TContent = {images: [], subheading: "", text: "", videos: []};
        props.section.content.push(content);
        forceUpdate();
    }

    const onContentRemove = (index: number) => {
        props.section.content.splice(index, 1);
        forceUpdate();
    }

    const onTagRemove = (index: number) => {
        props.section.tags.splice(index, 1);
        forceUpdate();
    }

    const onTagAdd = () => {
        let tag: TTag = {hex: "#f00", tag: "New tag"};
        props.section.tags.push(tag);
        forceUpdate();
    }

    useEffect(()=>{
        if(titleRef.current !== null){
            setTitle(props.section.title);
            titleRef.current.value = title;

        }
    },[props.section, titleRef, title, setTitle]);

    return (
        <Stack gap={8} className="section-box">
            <Row gap={8}>
                <h4>Title</h4>
                <input ref={titleRef} onChange={onTitleChanged} className="section-title-input" type="text" placeholder="Title"/>
                <button onClick={()=>{props.onRemove()}} className="section-remove">-</button>
            </Row>
            <Row gap={8}>
                <h4>Tags</h4>
                <button onClick={onTagAdd} className="tag-add">+</button>
            </Row>
            <div className="section-tag-box">
                {props.section.tags.map((tag, i)=> {
                    return (
                        <SectionTag onRemove={()=>{onTagRemove(i)}} tag={tag} />
                    );
                })}
            </div>
            <Row gap={8}>
            <h4>Content</h4>
                <button onClick={onContentAdd}  className="content-add">+</button>
            </Row>
            <div className="section-content-box">
                {props.section.content.map((content, i)=> {
                    return (
                        <Content onRemove={()=>{onContentRemove(i)}} content={content} ></Content>
                    );
                })}
            </div>
        </Stack>
    );
}

function Link(props: LinkProps) {

    const [,setTitle] = useState<string>(props.link.title);
    const [,setIcon] = useState<string>(props.link.iconUrl);
    const [,setUrl] = useState<string>(props.link.url);

    const titleRef = useRef<HTMLInputElement>(null);
    const iconUrlRef = useRef<HTMLInputElement>(null);
    const linkUrlRef = useRef<HTMLInputElement>(null);

    const onTitleChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.link.title = e.currentTarget.value;
        setTitle(e.currentTarget.value);
    }

    const onIconChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.link.iconUrl = e.currentTarget.value;
        setIcon(e.currentTarget.value);
    }
    const onUrlChanged = (e: React.FormEvent<HTMLInputElement>) => {
        props.link.url = e.currentTarget.value;
        setUrl(e.currentTarget.value);
    }

    useEffect(() => {
        if(titleRef.current !== null){
            titleRef.current.value = props.link.title;
            setTitle(titleRef.current.value);
        }
        if(iconUrlRef.current !== null){
            iconUrlRef.current.value = props.link.iconUrl;
            setIcon(iconUrlRef.current.value);
        }
        if(linkUrlRef.current !== null){
            linkUrlRef.current.value = props.link.url;
            setUrl(linkUrlRef.current.value);
        }
    }, [props.link.iconUrl, props.link.title, props.link.url]);

return(
    <Row gap={8} className={props.className ? "link-box " + props.className : "link-box"}>
        <input ref={titleRef} onChange={onTitleChanged}  className="link-text-input" type="text" placeholder="Display Name"/>
        <input ref={iconUrlRef} onChange={onIconChanged} className="link-text-input" type="text" placeholder="Icon Url"/>
        <input ref={linkUrlRef} onChange={onUrlChanged} className="link-text-input" type="text" placeholder="Link Url"/>
        <button onClick={()=>{props.onRemove()}} className="link-remove">-</button>
    </Row>
);
}

export default function EditorPage() {

    const [data, setData] = useState<TProject[]>(defaultData.projects);
    const [pageIndex, setPageIndex] = useState(0);

    const titleTextbox = useRef<HTMLInputElement>(null);
    const bannerTextbox = useRef<HTMLInputElement>(null);
    const modelTextbox = useRef<HTMLInputElement>(null);
    const writeupTextbox = useRef<HTMLTextAreaElement>(null);
    const showCheckbox = useRef<HTMLInputElement>(null);
    const inputFile = useRef<HTMLInputElement>(null);

    const setAllElements = useCallback(() => {

        if(titleTextbox.current !== null){
            titleTextbox.current.value = data[pageIndex].name;
        }

        if(writeupTextbox.current !== null){
            writeupTextbox.current.value = data[pageIndex].writeup;
        }

        if(bannerTextbox.current !== null){
            bannerTextbox.current.value = data[pageIndex].bannerUrl;
        }

        if(modelTextbox.current !== null){
            modelTextbox.current.value = data[pageIndex].modelUrl;
        }

        if(showCheckbox.current !== null){
            showCheckbox.current.checked = data[pageIndex].visible;
        }

    },[data, pageIndex])

    const downloadFile = () => {

        // create file in browser
        const fileName = "new-project-export";
        const json = JSON.stringify({projects: data}, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const onImport = () => {
        inputFile.current?.click();
    }

    const onFileUploaded = (event: React.FormEvent<HTMLInputElement>) => {

        if(event.currentTarget === null || event.currentTarget.files === null) {
            return;
        }

        const file:File = event.currentTarget.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                try {
                    if(e.target === null){
                        return;
                    }

                    let data: TProjects | null = null;

                    if (typeof e.target.result === "string") {
                         data = JSON.parse(e.target.result);
                    }

                    if(data !== null){
                        setData(data.projects);
                    }


                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            };
            reader.readAsText(file);
        }
    }

    const onExport = () => {
        downloadFile();
    }
    const onPageChange = (index: number) => {

        setPageIndex(index);

        setAllElements();

    }
    const onPageAdd = () => {
        let newPage:TProject = {
            bannerUrl: "",
            imageUrls: [],
            languages: [],
            linkedProjects: [],
            links: [],
            modelUrl: "",
            name: "Unnamed Page",
            sections: [],
            writeup: "This is a new page",
            visible: false,
        };

        setData([...data, newPage]);

    }

    const onImageUrlChange = (ref: React.RefObject<HTMLInputElement>, imageIndex: number) => {


        if(ref.current === undefined || ref.current === null || ref.current.value === null ) return;

        const newValue = ref.current.value;

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return { ...project, imageUrls: project.imageUrls.map((img, imgI)=>{

                        if(imgI === imageIndex) return newValue;
                        else return img;

                    }) }
            }
        }));


    }

    const onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, name: e.currentTarget.value};
            }
        }));
    }
    const onWriteupChange = (e: React.FormEvent<HTMLTextAreaElement>) => {

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, writeup: e.currentTarget.value};
            }
        }));
    }

    const onOverviewLanguageAdd = () => {

        let newLanguage:TLanguage = {language: "New Language", hex: "#f00"};

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, languages: [...project.languages, newLanguage]};
            }
        }));

    }
    const onOverviewLinkAdd = () => {

        let newLink:TLink = {iconUrl: "./res/link.svg", title: "New Link", url: "https://margoovian.com"};

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, links: [...project.links, newLink]};
            }
        }));

    }
    const onOverviewImageAdd = () => {

        let newImage:string = "/image/url";

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, imageUrls: [...project.imageUrls, newImage]};
            }
        }));

    }
    const onSectionAdd = () => {

        const newValue:TSection = {content: [], imageUrls: [], roles: [], tags: [], title: "New Section"};
        console.log(newValue);

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return { ...project, sections: [...project.sections, newValue] }
            }
        }));

    }

    const onOverviewLanguageRemove = (index:number) => {



        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, languages: project.languages.filter((language, langI) => langI !== index)};
            }
        }));

    }
    const onOverviewLinkRemove = (index:number) => {



        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, links: project.links.filter((link, linkI) => linkI !== index)};
            }
        }));

    }
    const onOverviewImageRemove = (index:number) => {

        console.log(index);

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, imageUrls: project.imageUrls.filter((url, imgI) => imgI !== index)};
            }
        }));

    }
    const onSectionRemove = (index:number) => {

        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, sections: project.sections.filter((section, secI) => secI !== index)};
            }
        }));


    }

    const onBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, bannerUrl: e.currentTarget.value};
            }
        }));
    }
    const onModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, modelUrl: e.currentTarget.value};
            }
        }));
    }

    const onShowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(data.map((project, i)=>{

            if(i !== pageIndex) return project;
            else {
                return {...project, visible: e.currentTarget.checked};
            }
        }));
    }

    useEffect(() => { setAllElements(); },[setAllElements])

    return (
        <main className="Editor-Page">
            <input onChange={onFileUploaded} accept=".json" type='file' id='file' ref={inputFile} style={{display: 'none'}}/>
            <div className="page-navigator">
                {data.map((p: TProject, i) => {
                    return (
                        <button onClick={() => {
                            onPageChange(i)
                        }} className="page-nav-button">{p.name}</button>
                    )
                })}

                <Stack className="control-buttons">
                    <Row>
                        <button onClick={onExport} className="export">Export</button>
                        <button onClick={onImport} className="import">Import</button>
                    </Row>
                    <button onClick={onPageAdd} className="add-page">Add Page</button>
                </Stack>

            </div>

            <div className="page-content">

                <Row gap={16}>
                    <h1>Page Title</h1>
                    <input ref={titleTextbox} className="title-input" type="text" placeholder="Title"
                           onChange={onTitleChange}/>
                </Row>
                <Row gap={16}>
                    <h1>Banner Url</h1>
                    <input ref={bannerTextbox} className="title-input" type="text" placeholder="Banner Url"
                           onChange={onBannerChange}/>
                </Row>
                <Row gap={16}>
                    <h1>Model Url</h1>
                    <input ref={modelTextbox} className="title-input" type="text" placeholder="Model Url"
                           onChange={onModelChange}/>
                </Row>

                <Row gap={16}>
                    <h1>Show Page</h1>
                    <input ref={showCheckbox} className="title-check" type="checkbox"
                           onChange={onShowChange}/>
                </Row>

                <Stack gap={4}>
                    <h1>Page Writeup</h1>
                    <textarea ref={writeupTextbox} className="writeup-input" placeholder="Write up" cols={2} rows={2}
                              onChange={onWriteupChange}/>
                </Stack>

                <Stack gap={4}>
                    <Row gap={16}>
                        <h1>Languages</h1>
                        <button className="overview-language-add" onClick={onOverviewLanguageAdd}>+</button>
                    </Row>
                    <div className="overview-language-box">
                        {data[pageIndex].languages.map((language: TLanguage, i) => {
                            return (
                                <Language onRemove={() => {
                                    onOverviewLanguageRemove(i)
                                }} language={language} key={i}/>
                            )
                        })}
                    </div>
                </Stack>

                <Stack gap={4}>
                    <Row gap={16}>
                        <h1>Images</h1>
                        <button className="overview-image-add" onClick={onOverviewImageAdd}>+</button>
                    </Row>
                    <div className="overview-image-box">
                        {data[pageIndex].imageUrls.map((image: string, i) => {
                            return (
                                <OverviewImage setter={(ref) => {
                                    onImageUrlChange(ref, i)
                                }} imageUrl={image} onRemove={() => {
                                    onOverviewImageRemove(i)
                                }} key={i}/>
                            );
                        })}
                    </div>
                </Stack>

                <Stack gap={4}>
                    <Row gap={16}>
                        <h1>Links</h1>
                        <button className="overview-link-add" onClick={onOverviewLinkAdd}>+</button>
                    </Row>
                    <div className="overview-link-box">
                        {data[pageIndex].links.map((link: TLink, i) => {
                            return (
                                <Link onRemove={() => {
                                    onOverviewLinkRemove(i)
                                }} link={link} key={i}/>
                            )
                        })}
                    </div>
                </Stack>

                <Stack gap={4}>
                    <Row gap={16}>
                        <h1>Sections</h1>
                        <button className="overview-sections-add" onClick={onSectionAdd}>+</button>
                    </Row>
                    <div className="overview-sections-box">
                        {data[pageIndex].sections.map((section: TSection, i) => {
                            return (
                                <Section section={section} onRemove={() => {
                                    onSectionRemove(i)
                                }} key={i}/>
                            );
                        })}
                    </div>
                </Stack>

            </div>

        </main>
    );
}