import React, {Suspense} from "react";
import {Cartridge, CartridgeProps} from "./Cartridge";
import * as THREE from "three";
import ShadowPlane from "./ShadowPlane";
import "./styles/SoloCartridge.scss";

import {useNavigate} from "react-router";
import {Canvas} from "@react-three/fiber";

interface SoloCartridgeProps {

    //modal: React.RefObject<ProjectModal>,
    projectPage: string,
    positionOffset?: THREE.Vector2,
    model: string,
    name: string,
    textColor: string,
}

const SoloCartridge = (props:SoloCartridgeProps) => {

    const navigate = useNavigate();

    //const openModal = (newModalState: ProjectModalState): void => {
    //    props.modal.current?.setState({
    //        imageUrl: newModalState.imageUrl,
    //        writeup: newModalState.writeup,
    //        title: newModalState.title,
    //    })
    //    props.modal.current?.setOpenState(true);
    //}

    const position = props.positionOffset !== undefined ? props.positionOffset : new THREE.Vector2(0,0);

    return (
        <div className="cartridge-container">
            <Canvas className="cartridge-canvas">
                <Suspense fallback={null}>
                    <Cartridge
                        onClick={(): void => {
                            navigate(props.projectPage);
                            window.scrollTo(0, 0)
                            // openModal({
                            //     imageUrl:physEngineProject.imageUrl,
                            //     writeup: physEngineProject.writeup,
                            //     title:physEngineProject.name,
                            // });
                        }}
                        position={position}
                        model={props.model}
                        name={props.name}
                        textColor={props.textColor}
                        scale={5.5}
                        fontOffset={-3}
                    />
                </Suspense>

                <ShadowPlane/>

                <spotLight
                    castShadow
                    color={"#ffffff"}
                    position={[0, 50, 0]}
                    intensity={10}
                    penumbra={1}
                    angle={Math.PI / 10}
                    shadow-mapSize-width={2048}
                    shadow-mapSize-height={2048}
                />

                {/* White ambient light */}
                <ambientLight intensity={2}/>

            </Canvas>
        </div>
    );
}

export default SoloCartridge;